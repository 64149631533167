import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
// import Logo from "src/component/Logo";

// const useStyles = makeStyles((theme) => ({
//   content: {
//     height: "100vh",
//     overflowX: "auto",
//     background: "#ffffff",
//     [theme.breakpoints.down("sm")]: {
//       height: "auto",
//       overflow: "unset",
//     },
//   },
//   left: {
//     // background: 'url(/images/login-left.jpg) no-repeat',
//     backgroundPosition: "center right",
//     height: "100vh",
//     [theme.breakpoints.down("sm")]: {
//       height: 300,
//     },
//     [theme.breakpoints.down("xs")]: {
//       height: 200,
//     },
//   },
// }));

const LoginLayout = ({ children }) => {
  // const classes = useStyles();

  return (
    <Grid container style={{ backgroundColor: "#F5F5F5" }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{
          justifyContent: "center",
          alignItems: "center",

          height: "100vh",
        }}
      >
        {children}
      </Grid>
      {/* <Grid item xs={12} sm={12} md={5} className={classes.left}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Logo style={{ width: '80%', maxWidth: 480 }} />
        </Box>
      </Grid> */}
    </Grid>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
