/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";

import { PieChart as PieChartIcon } from "react-feather";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import NavItem from "./NavItem";
import { FaFileImage } from "react-icons/fa";
import { BsStopwatch } from "react-icons/bs";
const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/dashboard",
      },
      // {
      //   title: "Login",
      //   icon: PieChartIcon,
      //   href: "/",
      // },
      {
        title: "Large Ad",
        icon: PieChartIcon,
        href: "/largead",
      },
      {
        title: "Small Ad",
        icon: PieChartIcon,
        href: "/smallad",
      },
      {
        title: "Requirement",
        icon: PieChartIcon,
        href: "/requirement",
      },
      {
        title: "Promotted",
        icon: PieChartIcon,
        href: "/promotted",
      },
      {
        title: "Top and Right Ad",
        icon: PieChartIcon,
        href: "/top-and-right-ad",
      },

      {
        title: "Image management",
        icon: FaFileImage,
        href: "/image-management",
      },
      {
        title: "Featured List",
        icon: FeaturedPlayListIcon,
        href: "/features-list",
      },
      {
        title: "Active List",
        icon: BsStopwatch,
        href: "/active-list",
      },
      {
        title: "Settings",
        icon: BsStopwatch,
        href: "/setting",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 256,
    top: 80,
    height: "calc(100% - 80px)",
    background: theme.palette.primary.main,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
