import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
// import { AuthContext } from "src/context/Auth";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Badge,
} from "@material-ui/core";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import SwipeableTemporaryDrawer from "./RightDrawer";
import { useHistory } from "react-router-dom";
import Logo from "src/component/Logo";
// import { LogOut } from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.paper,
  },
  toolbar: {
    height: 80,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const history = useHistory();

  const LogOutHandler = () => {
    localStorage.removeItem("token");
    history.push("/");
  };

  // const auth = useContext(AuthContext);
  return (
    <>
      <Logo
        width="180"
        onClick={() => history.push("/dashboard")}
        style={{ cursor: "pointer", paddingLeft: 16 }}
      />
      <Box flexGrow={1} />

      <IconButton style={{ marginRight: 10 }} onClick={LogOutHandler}>
        <Badge color="secondary">
          <ExitToAppIcon style={{ color: "#ffffff" }} />
        </Badge>
      </IconButton>
      {/* <SwipeableTemporaryDrawer /> */}
    </>
  );
}
