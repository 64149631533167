import { Box } from "@material-ui/core";
import React from "react";

const Logo = (props) => {
  return (
    <Box
      style={{
        background: "#fff",
        padding: 10,
        borderRadius: "50%",
        width: 40,
        height: 40,
      }}
    >
      <img
        src="images/logo.jpeg"
        alt="Logo"
        style={{ width: "100%" }}
        // {...props}
      />
    </Box>
  );
};

export default Logo;
