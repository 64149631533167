import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },

  {
    exact: true,
    path: "/largead",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LargeAd")),
  },
  {
    exact: true,
    path: "/smallad",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/smallAd")),
  },
  {
    exact: true,
    path: "/requirement",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/requirement")),
  },
  {
    exact: true,
    path: "/promotted",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/vetted")),
  },
  {
    exact: true,
    path: "/top-and-right-ad",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TopAndRightAd")),
  },

  {
    exact: true,
    path: "/image-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ImageManagement")),
  },
  {
    exact: true,
    path: "/upload",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Uploadcsv")),
  },
  {
    exact: true,
    path: "/publish",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Publish")),
  },
  {
    exact: true,
    path: "/active-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ActiveList")),
  },
  {
    exact: true,
    path: "/features-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/FeaturesManagement")),
  },
  {
    exact: true,
    path: "/setting",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/setting/index")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
